import * as React from 'react';
import { PageProps } from 'gatsby';
import { PageLayout as Layout } from '../../components/Layouts/PageLayout/PageLayout';
import { Seo } from '../../components/Seo/Seo';

import { PageTitle } from '../../components/ui/pageTitle/pageTitle';

import * as scss from './winners.module.scss';
import Rules from 'src/components/ui/formOverlay/Form/Rules';

export const HowToSell: React.FC<PageProps> = ({ location }) => {
  if (!location.hostname) return null;

  return (
    <Layout className="how-to-sell bg bg-training after-header-spacer">
      <Seo title="How to Sell" />
      <PageTitle title="How to Sell" />

      <div className={scss.container}>
        <h1 className={scss.title}>WONKA SWEET-STAKES WINNERS</h1>

        <table className={scss.table}>
          <thead>
            <tr>
              <th title="First Name">First Name</th>
              <th title="Last Name (Initial)">Last Name (Initial)</th>
              <th title="City">City</th>
              <th title="State">State</th>
            </tr>
          </thead>
        </table>

        <Rules />
      </div>
    </Layout>
  );
};

export default HowToSell;
